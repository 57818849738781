<template>
  <div class="box">
    <div class="headline">手册编写说明</div>
    <div class="content">
        <el-table :data="tableData" border>
        <el-table-column prop="format" label="格式" ></el-table-column>
        <el-table-column prop="sense" label="意义"></el-table-column>
        </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          format: "【系统模块】",
          sense: "系统对应的模块",
        },
        {
          format: "“操作按钮”",
          sense: "窗口中的功能按钮",
        },
        {
          format: "《系统字段》",
          sense: "页码显示的字段",
        },
      ],
    };
  },
};
</script>
